import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private domain: string = '';
  private language: string = '';

  constructor(
    @Inject(PLATFORM_ID) private platform: Object
  ) {
    this.setDomainAndLanguage();
  }

  setDomainAndLanguage(): void {
     if (isPlatformBrowser(this.platform)) { 
       const hostname = window.location.hostname;
       const pathname = window.location.pathname;
   
       if (hostname.includes('localhost')) {
         this.domain = 'localhost';
       } else if (hostname.includes('ideku.id')) {
         this.domain = 'ideku.id';
       } else if (hostname.includes('ideku.io')) {
         this.domain = 'ideku.io';
       } else if (hostname.includes('ideku.tw')) {
         this.domain = 'ideku.tw';
       } 
   
       if (pathname.startsWith('/id')) {
         this.language = 'id';
       } else if (pathname.startsWith('/en')) {
         this.language = 'en';
       } else if (pathname.startsWith('/tw')) {
         this.language = 'tw';
       } else if (pathname.startsWith('/zh-TW')) {
         this.language = 'zh-TW';
      } else if (pathname.startsWith('/zh-CN')) {
         this.language = 'zh-CN';
       } else {
         this.language = 'en'; // Default language
       }
    }
  }

  getDomain(): string {
    return this.domain;
  }

  getLanguage(): string {
    let result = this.language
    if (!isPlatformBrowser(this.platform)) result = 'en';

    return result;
  }

  setLanguage(language: string): void {
    this.language = language;
    // window.location.pathname = `/${language}${window.location.pathname.substring(3)}`;
    window.location.pathname = `/${language}`;
  }
}
