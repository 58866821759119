<nav
  class="navbar navbar-expand-lg navbar-light fixed-top navbar-menu"
  [ngStyle]="{
    background: header?.bg
  }"
>
  <div class="container">
    <a class="navbar-brand" href="/{{currentLanguage}}">
      <img
        src="assets/images/png/logo-ideku-black.png"
        width="100"
        alt="ideku"
        fetchpriority="low"
        loading="lazy"
      />
    </a>
    <button
      class="navbar-toggler"
      type="button"
      (click)="isCollapsed = !isCollapsed"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" [class.show]="!isCollapsed">
      <ul class="navbar-nav ms-auto align-items-center" *ngIf="!hideMenu">
        <li class="nav-item page dropdown product-dropdown">
          <a
            class="nav-link"
            href="javascript:void(0)"
            (click)="dropdownClick('product')"
          >
            {{ "NAV_MENU.PRODUCT" | translate }}
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_5238_1931)">
                <path
                  d="M10.5492 4.66059L6.34922 8.2713C6.29922 8.31416 6.24505 8.34445 6.18672 8.36216C6.12839 8.38016 6.06589 8.38916 5.99922 8.38916C5.93255 8.38916 5.87005 8.38016 5.81172 8.36216C5.75339 8.34445 5.69922 8.31416 5.64922 8.2713L1.43672 4.66059C1.32005 4.56059 1.26172 4.43559 1.26172 4.28559C1.26172 4.13559 1.32422 4.00702 1.44922 3.89988C1.57422 3.79273 1.72005 3.73916 1.88672 3.73916C2.05339 3.73916 2.19922 3.79273 2.32422 3.89988L5.99922 7.04987L9.67422 3.89987C9.79089 3.79987 9.93455 3.74987 10.1052 3.74987C10.2762 3.74987 10.4242 3.80345 10.5492 3.91059C10.6742 4.01773 10.7367 4.14273 10.7367 4.28559C10.7367 4.42845 10.6742 4.55345 10.5492 4.66059Z"
                  fill="#404040"
                />
              </g>
              <defs>
                <clipPath id="clip0_5238_1931">
                  <rect
                    width="10.2857"
                    height="12"
                    fill="white"
                    transform="translate(0 11.1428) rotate(-90)"
                  />
                </clipPath>
              </defs>
            </svg>
          </a>
          <div class="dropdown-content" *ngIf="showProductDropdown">
            <a routerLink="/{{currentLanguage}}/idepos">IDEPOS</a>
            <a href="javascript:void(0)" class="dropdown-content-nested-2">
              <div class="text-menu">
                <span>IDEFOOD</span>
                <span>
                  <svg
                    width="6"
                    height="11"
                    viewBox="0 0 6 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.80268 0.950781L5.41339 5.15078C5.45625 5.20078 5.48654 5.25495 5.50425 5.31328C5.52225 5.37161 5.53125 5.43411 5.53125 5.50078C5.53125 5.56745 5.52225 5.62995 5.50425 5.68828C5.48654 5.74661 5.45625 5.80078 5.41339 5.85078L1.80268 10.0633C1.70268 10.1799 1.57768 10.2383 1.42768 10.2383C1.27768 10.2383 1.14911 10.1758 1.04197 10.0508C0.934822 9.92578 0.88125 9.77995 0.88125 9.61328C0.88125 9.44661 0.934822 9.30078 1.04197 9.17578L4.19196 5.50078L1.04197 1.82578C0.941965 1.70911 0.891965 1.56545 0.891965 1.39478C0.891965 1.22378 0.945536 1.07578 1.05268 0.950781C1.15982 0.825781 1.28482 0.763281 1.42768 0.763281C1.57054 0.763281 1.69554 0.825781 1.80268 0.950781Z"
                      fill="#404040"
                    />
                  </svg>
                </span>
              </div>

              <div class="dropdown-content-nested-item-2">
                <a routerLink="/{{currentLanguage}}/idefood/idefood-qr">IDEFOOD QR</a>
                <a routerLink="/{{currentLanguage}}/idefood/idefood-pda">IDEFOOD PDA</a>
              </div>
            </a>
            <a routerLink="/{{currentLanguage}}/ideque">IDEQUE</a>
            <a routerLink="/{{currentLanguage}}/idekiosk">IDEKIOSK</a>
            <a routerLink="/{{currentLanguage}}/kds-eds">KDS/EDS</a>
            <a routerLink="/{{currentLanguage}}/idepage">IDEPAGE</a>
          </div>
        </li>

        <li class="nav-item page">
          <a
            class="nav-link"
            routerLink="/{{currentLanguage}}/about-us"
            routerLinkActive="active"
            >{{ "NAV_MENU.ABOUT_US" | translate }}</a
          >
        </li>

        <li class="nav-item page dropdown integration-dropdown">
          <a
            class="nav-link"
            href="javascript:void(0)"
            (click)="dropdownClick('integration')"
          >
            {{ "NAV_MENU.INTEGRATION" | translate }}
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_5238_1931)">
                <path
                  d="M10.5492 4.66059L6.34922 8.2713C6.29922 8.31416 6.24505 8.34445 6.18672 8.36216C6.12839 8.38016 6.06589 8.38916 5.99922 8.38916C5.93255 8.38916 5.87005 8.38016 5.81172 8.36216C5.75339 8.34445 5.69922 8.31416 5.64922 8.2713L1.43672 4.66059C1.32005 4.56059 1.26172 4.43559 1.26172 4.28559C1.26172 4.13559 1.32422 4.00702 1.44922 3.89988C1.57422 3.79273 1.72005 3.73916 1.88672 3.73916C2.05339 3.73916 2.19922 3.79273 2.32422 3.89988L5.99922 7.04987L9.67422 3.89987C9.79089 3.79987 9.93455 3.74987 10.1052 3.74987C10.2762 3.74987 10.4242 3.80345 10.5492 3.91059C10.6742 4.01773 10.7367 4.14273 10.7367 4.28559C10.7367 4.42845 10.6742 4.55345 10.5492 4.66059Z"
                  fill="#404040"
                />
              </g>
              <defs>
                <clipPath id="clip0_5238_1931">
                  <rect
                    width="10.2857"
                    height="12"
                    fill="white"
                    transform="translate(0 11.1428) rotate(-90)"
                  />
                </clipPath>
              </defs>
            </svg>
          </a>
          <div class="dropdown-content-nested" *ngIf="showIntegrationDropdown">
            <app-dropdown [menuItems]="menuIntegrationItems"></app-dropdown>
          </div>
        </li>

        <!-- <li class="nav-item page" *ngIf="country === 'SG'">
          <a class="nav-link" routerLink="/{{currentLanguage}}/rental" routerLinkActive="active">
            Rental
          </a>
        </li> -->

        <!-- <li class="nav-item page" *ngIf="country === 'SG'">
          <a class="nav-link" routerLink="/promo" routerLinkActive="active">
            Promo
          </a>
        </li> -->

        <!-- <li class="nav-item page" *ngIf="currentLanguage === 'id'">
          <a
            class="nav-link"
            [class.text-white]="isCareer"
            href="https://blog.ideku.id/{{currentLanguage}}"
            >{{ "NAV_MENU.BLOG" | translate }}</a
          >
        </li> -->
        <li class="nav-item page">
          <a
            class="nav-link"
            [class.text-white]="isCareer"
            routerLink="/{{currentLanguage}}/career"
            routerLinkActive="active"
            >{{ "NAV_MENU.CAREER" | translate }}</a
          >
        </li>

        <li class="nav-item page dropdown dropdown-language language-dropdown">
          <a
            class="nav-link"
            href="javascript:void(0)"
            (click)="dropdownClick('language')"
          >
            <ng-container *ngIf="currentLanguage === 'id'">
              <img
                src="assets/images/logo/id-logo.png"
                alt="logo Indonesia"
                fetchpriority="low"
                loading="lazy"
              />
              ID
            </ng-container>

            <ng-container *ngIf="currentLanguage === 'en'">
              <img
                src="assets/images/logo/sg-logo.png"
                alt="logo Singapore"
                fetchpriority="low"
                loading="lazy"
              />
              SG (EN)
            </ng-container>

            <ng-container *ngIf="currentLanguage === 'zh-TW'">
               <img
                src="assets/images/logo/tw-logo.svg"
                alt="logo taiwan"
                fetchpriority="low"
                loading="lazy"
                width="20px"
              />
                繁體中文
            </ng-container>

            <ng-container *ngIf="currentLanguage === 'zh-CN'">
              <img
                src="assets/images/logo/cn-logo.svg"
                alt="logo cina"
                fetchpriority="low"
                loading="lazy"
                width="20px"
              />
              简体中文
            </ng-container>
          </a>
          <div class="dropdown-content" *ngIf="showLanguageDropdown">
            <a
              href="javascript:void(0)"
              *ngFor="let lang of sortBy('order')"
              (click)="setLanguage(lang.value, true)"
            >
              <img
                *ngIf="lang.image.length > 0"
                [src]="lang.image"
                [alt]="'language ' + lang.name"
                fetchpriority="low"
                loading="lazy"
                width="20"
              />
              <p>{{ lang.name }}</p>
            </a>
          </div>
        </li>

        <li class="nav-item page" *ngIf="!isIdepage">
          <button class="btn-gradient-danger" routerLink="/{{currentLanguage}}/contact-us">
            {{ "NAV_MENU.CONTACT_US" | translate }}
          </button>
        </li>

         <li class="nav-item nav-btn" *ngIf="isIdepage">
          <a class="nav-link" href="https://idepage.io/my-portal/login">
            <button class="outlined-button">
              {{ "NAV_MENU.LOGIN_BUTTON" | translate }}
            </button>
          </a>
        </li>
        <li class="nav-item nav-btn" *ngIf="isIdepage">
          <a class="nav-link" href="https://idepage.io/my-portal/splash">
            <button class="filled-button">
              {{ "NAV_MENU.TRY_BUTTON" | translate }}
            </button>
          </a>
        </li>
      </ul>

      <ul class="navbar-nav ms-auto align-items-center" *ngIf="hideMenu">
        <li class="nav-item page">
          <button *ngIf="scrollToID" class="btn-gradient-danger" (click)="scrollToElement()">
            {{ "NAV_MENU.CONTACT_US" | translate }}
          </button>
          <button *ngIf="!scrollToID" class="btn-gradient-danger" routerLink="/{{currentLanguage}}/contact-us">
            {{ "NAV_MENU.CONTACT_US" | translate }}
          </button>
        </li>
      </ul>
    </div>
  </div>
</nav>
