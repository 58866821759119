import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  Optional,
  PLATFORM_ID,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import {
  Meta,
  Title,
  TransferState,
  makeStateKey,
} from '@angular/platform-browser';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as AOS from 'aos';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { filter, map, mergeMap } from 'rxjs';
import { GtmService } from './shared/data-access/gtm-service/gtm-service.service';
import { ViewService } from './shared/data-access/view/view.service';
import { ConfigService } from './shared/services/config.service';
import { MetaService } from './shared/services/meta.service';
import { REQUEST } from '@nguniversal/express-engine/tokens';

@Component({
  selector: 'app-root',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'ideku.id';
  isBrowser?: boolean;

  langCode?: string = 'id';
  year!: string;

  currentPath: string = ''


  constructor(
    private translate: TranslateService,
    private transferState: TransferState,
    @Inject(DOCUMENT) private document: any,
    @Inject(PLATFORM_ID) platform: Object,
    @Optional() @Inject('LANGUAGE') public language: string,
    @Optional() @Inject('COUNTRY_CODE') public headerCountryCode: string,
    private renderer2: Renderer2,
    private _cookieServiceSSR: SsrCookieService,
    private _activatedRoute: ActivatedRoute,
    private cdref: ChangeDetectorRef,
    private _viewService: ViewService,
    private _configService: ConfigService,
    private router: Router, 
    private metaService: MetaService,
    private _gtmService: GtmService,
    @Optional() @Inject(REQUEST) public request: Request,
    @Optional() @Inject('DOMAIN') public domain: string
  ) {
    const LANG_KEY = makeStateKey<string>('langCode');
    const COUNTRY_KEY = makeStateKey<string>('countryCode');
    const REQ_URL_KEY = makeStateKey<string>('reqUrl');
    const DOMAIN_KEY = makeStateKey<string>('domain');

    let langCode: string;
    let countryCode: string;
    let reqUrl: string;
    let domainWeb: string;
    if (isPlatformBrowser(platform)) {
      langCode = this.transferState.get(LANG_KEY, 'en');
      countryCode = this.transferState.get(COUNTRY_KEY, 'SG');
      reqUrl = this.transferState.get(REQ_URL_KEY, '');
      domainWeb = this.transferState.get(DOMAIN_KEY, 'www.ideku.io');
      this.isBrowser = isPlatformBrowser(platform);
      this._viewService.setLocalStorage('countryCode', countryCode);
    } else {
      this.transferState.set(COUNTRY_KEY, this.headerCountryCode);
      this.transferState.set(REQ_URL_KEY, this.request.url);
      this.transferState.set(DOMAIN_KEY, this.domain);
      langCode = this.language;
      countryCode = this.headerCountryCode;
      reqUrl = this.request.url
      domainWeb = this.domain
    }

    translate.addLangs(['en', 'id', 'zh-TW']);
    translate.setDefaultLang('en');
    translate.use(this._configService.getLanguage());

    if (countryCode === 'ID') {
      this._gtmService.initializeGtm('GTM-PLDW6NWR')
    } else {
      this._gtmService.initializeGtm('GTM-M4CJJKQW')
    }

    this.metaService.setMetaTags(reqUrl, domainWeb);
    this.metaService.updateKeywords()
  }

  ngOnInit(): void {
    if (this.isBrowser) {
      AOS.init(); //AOS - 2
      AOS.refresh(); //refresh method is called on window resize and so on, as it doesn't require to build new store with AOS elements and should be as light as possible.
    }
  }

  ngAfterViewInit(): void {
    this._activatedRoute.queryParams.forEach((params) => {
      if (params['langCode'] === 'en' || params['langCode'] === 'id') {
        this.language = params['langCode'];
        this._cookieServiceSSR.set('langCode', this.language);
        this.translate.use(this.language);
      }
    });

    this.setLiveChat();

    this.cdref.detectChanges();
  }

  setAnalyticsGtag(idTracking: string) {
    this.addScript(
      this.renderer2,
      null,
      `https://www.googletagmanager.com/gtag/js?id=${idTracking}`
    );

    let dataText = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        
        gtag('config', '${idTracking}');
    `;
    this.addScript(this.renderer2, dataText, null);
  }

  public addScript(renderer2: Renderer2, dataText: any, dataSrc: any): void {
    let script = renderer2.createElement('script');
    if (dataText) {
      script.text = dataText;
      script.defer = true;
    } else if (dataSrc) {
      script.src = dataSrc;
      script.defer = true;
    }
    renderer2.appendChild(this.document.body, script);
  }

  setLiveChat() {
    let dataText = `
        var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode:"siq292a1d42c7c33bb2a1cb85f6c0e2591158ad06b007517d6516334413dba6f374b801a27b6b25342664d84d3ecdde10f9", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zoho.com/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);d.write("<div id='zsiqwidget'></div>");
    `;
    let script = this.renderer2.createElement('script');
    script.text = dataText;
    script.defer = true;
    this.renderer2.appendChild(this.document.body, script);
  }
}
