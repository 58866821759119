import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

interface MenuItem {
  label: string;
  link?: string;
  subItems?: MenuItem[];
}

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  imports: [CommonModule, TranslateModule],
  standalone: true
})
export class DropdownComponent {
  @Input() menuItems: any = [];

//  menuItems = [
//     {
//       label: 'Item 1',
//       showSubmenu: false,
//       submenu: [
//         {
//           label: 'Sub Item 1.1',
//           submenu: [
//             { label: 'Sub Sub Item 1.1.1' },
//             { label: 'Sub Sub Item 1.1.2' }
//           ]
//         },
//         { label: 'Sub Item 1.2' }
//       ]
//     },
//     {
//       label: 'Item 2',
//       showSubmenu: false,
//       submenu: [
//         { label: 'Sub Item 2.1' },
//         { label: 'Sub Item 2.2' }
//       ]
//     },
//     {
//       label: 'Item 3',
//       showSubmenu: false,
//       submenu: [
//         { label: 'Sub Item 3.1' },
//         { label: 'Sub Item 3.2' }
//       ]
//     }
//   ];

  showSubmenu(item: any) {
    item.showSubmenu = true;
  }

  hideSubmenu(item: any) {
    item.showSubmenu = false;
  }
}
