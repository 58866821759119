<ul class="menu">
  <li class="menu-item" *ngFor="let item of menuItems" (mouseenter)="showSubmenu(item)" (mouseleave)="hideSubmenu(item)">
    <a href="javascript:void(0)">
        <span>{{ item.label | translate }}</span>

        <svg _ngcontent-ideku-id-c24="" width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path _ngcontent-ideku-id-c24="" d="M1.80268 0.950781L5.41339 5.15078C5.45625 5.20078 5.48654 5.25495 5.50425 5.31328C5.52225 5.37161 5.53125 5.43411 5.53125 5.50078C5.53125 5.56745 5.52225 5.62995 5.50425 5.68828C5.48654 5.74661 5.45625 5.80078 5.41339 5.85078L1.80268 10.0633C1.70268 10.1799 1.57768 10.2383 1.42768 10.2383C1.27768 10.2383 1.14911 10.1758 1.04197 10.0508C0.934822 9.92578 0.88125 9.77995 0.88125 9.61328C0.88125 9.44661 0.934822 9.30078 1.04197 9.17578L4.19196 5.50078L1.04197 1.82578C0.941965 1.70911 0.891965 1.56545 0.891965 1.39478C0.891965 1.22378 0.945536 1.07578 1.05268 0.950781C1.15982 0.825781 1.28482 0.763281 1.42768 0.763281C1.57054 0.763281 1.69554 0.825781 1.80268 0.950781Z" fill="#404040"></path></svg>
    </a>
    <ul class="submenu" *ngIf="item.showSubmenu">
      <li class="submenu-item" *ngFor="let subItem of item.submenu">
        <a  [href]="subItem.link === '#' ? 'javascript:void(0)' :  subItem.link">{{ subItem.label }}</a>
        <!-- <ul class="subsubmenu" *ngIf="subItem.hasOwnProperty('submenu')">
          <li class="subsubmenu-item" *ngFor="let subSubItem of subItem.submenu">
            <a href="#">{{ subSubItem.label }} c</a>
          </li>
        </ul> -->
      </li>
    </ul>
  </li>
</ul>
