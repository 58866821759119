<footer>
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div class="col-lg-3 d-lg-block d-flex flex-column align-items-center pb-4">
        <img src="assets/images/logo/logo-ideku-white.png?12" class="logo" alt="logo ideku" fetchpriority="low"
          loading="lazy" />

        <p class="text-desc">
          {{ 'IDEKU_DESC_FOOTER' | translate }}
        </p>

        <div class="social-media">
          <a href="https://www.instagram.com/ideku.{{
              countryCode === 'ID' ? 'idn' : 'io'
            }}" target="_blank">
            <img src="assets/images/png/instagram-2.png" alt="logo instagram" fetchpriority="low" loading="lazy" />
          </a>
          <a href="https://www.tiktok.com/@ideku{{
              countryCode === 'ID' ? 'id' : '.io'
            }}" target="_blank">
            <img src="assets/images/png/tik-tok-2.png" alt="logo tiktok" fetchpriority="low" loading="lazy" />
          </a>
          <a href="https://www.facebook.com/ideku.{{
              countryCode === 'ID' ? 'idn' : '.io'
            }}" target="_blank">
            <img src="assets/images/png/facebook-2.png" alt="logo facebook" fetchpriority="low" loading="lazy" />
          </a>
          <a href="https://www.youtube.com/@ideku{{
              countryCode === 'ID' ? 'id' : 'io'
            }}" target="_blank">
            <img src="assets/images/png/youtube-2.png" alt="logo youtube" fetchpriority="low" loading="lazy" />
          </a>
          <a href="https://www.linkedin.com/company/ideku-io" target="_blank">
            <img src="assets/images/png/logo-linkedin-3.png" alt="logo linkedin" fetchpriority="low" loading="lazy" />
          </a>
        </div>
      </div>
      <div class="col-lg-5 mb-4 mb-lg-0">
        <div class="row">
          <div class="col-md-4 col-12">
            <h3 class="title-menu">{{ "QUICK_LINKS" | translate }}</h3>
            <ul>
              <li>
                <a routerLink="/{{currentLanguage}}/about-us">
                  {{ "FOOTER_NAV.ABOUT_US" | translate }}
                </a>
              </li>
              <li>
                <a routerLink="/{{currentLanguage}}/reseller-partner-form"> {{
                  "FOOTER_NAV.RESELLER_PARTNER" | translate
                }}  </a>
              </li>
              <li>
                <a routerLink="/{{currentLanguage}}/blog"> {{
                  "FOOTER_NAV.BLOG" | translate
                }} </a>
              </li>
              <!-- <li>
                <a routerLink="/promo"> Promo </a>
              </li> -->
              <li>
                <a routerLink="/{{currentLanguage}}/contact-us">{{
                  "FOOTER_NAV.CONTACT" | translate
                  }}</a>
              </li>
              <li>
                <a routerLink="/{{currentLanguage}}/career">
                  {{ "FOOTER_NAV.CAREER" | translate }}
                </a>

                <span class="we-are-hiring">{{ 'WE_ARE_HIRING' | translate }}</span>
              </li>
            </ul>
          </div>
          <div class="col-md-4 col-12">
            <h3 class="title-menu">{{ 'FOOTER_NAV.PRODUCTS' | translate }}</h3>
            <ul>
              <li>
                <a routerLink="/{{currentLanguage}}/idepos">IDEPOS</a>
              </li>
              <li>
                <a routerLink="/{{currentLanguage}}/idefood/idefood-qr">IDEFOOD</a>
              </li>
              <li>
                <a routerLink="/{{currentLanguage}}/ideque">IDEQUE</a>
              </li>
               <li>
                <a routerLink="/{{currentLanguage}}/idekiosk">IDEKIOSK</a>
              </li>
               <li>
                <a routerLink="/{{currentLanguage}}/kds-eds">KDS/EDS</a>
              </li>
              <li>
                <a routerLink="/{{currentLanguage}}/idepage">IDEPAGE </a>
              </li>
            </ul>
          </div>
          <div class="col-lg-4 subscribe">
            <div class="row">
              <div class="col-lg-12">
                <h3 class="title-menu">{{ "REACH_US" | translate }}</h3>

                <ul class="reach-us">
                  <li class="mb-3">
                    <img src="assets/images/png/ic-email-icon.png" alt="ic-email-icon" fetchpriority="low"
                      loading="lazy" />
                    <a href="mailto:info@ideku.io"> info@ideku.io </a>
                  </li>
                  <li class="mb-3">
                    <img src="assets/images/png/ic-phone-icon.png" alt="ic-phone-icon" fetchpriority="low"
                      loading="lazy" />
                    <a href="tel:+6587747121"> +65 87747121 </a>
                  </li>
                  <!-- <li class="mb-3">
                    <img
                      src="assets/images/png/ic-location-icon.png"
                      alt="ic-location-icon"
                      fetchpriority="low"
                      loading="lazy"
                    />
                    <p>
                      60 Paya Lebar Road #11-47, Paya Lebar Square, Singapore,
                      409051
                    </p>
                  </li> -->
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="row">
          <div class="col-lg-12">
            <h3 class="title-menu">{{ "OUR_OFFICE" | translate }}</h3>

            <ul class="location-list">
              <li class="mb-3">
                <div class="location-item" style="align-items: flex-start;">
                  <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M9.03 1.99535e-05C11.29 0.01002 13.45 0.91002 15.03 2.49002C16.62 4.08002 17.51 6.23002 17.5 8.46002V8.51002C17.44 11.54 15.74 14.33 13.62 16.51C12.42 17.74 11.09 18.83 9.64 19.75C9.25 20.08 8.68 20.08 8.29 19.75C6.14 18.35 4.24 16.59 2.7 14.54C1.35 12.76 0.58 10.62 0.5 8.39002C0.52 3.74002 4.34 -0.00998005 9.03 1.99535e-05ZM9.03 11.26C9.74 11.26 10.42 10.99 10.92 10.5C11.44 9.99002 11.731 9.31102 11.731 8.60002C11.731 7.12002 10.52 5.93002 9.03 5.93002C7.54 5.93002 6.34 7.12002 6.34 8.60002C6.34 10.061 7.52 11.24 9 11.26H9.03Z"
                      fill="#F3F4F8" />
                  </svg>

                  <div style="display: flex; flex-direction: column">
                    <span>{{ 'SINGAPORE' | translate }}</span>
                    <span style="font-size: 12px; font-weight: 400; color: #eee;">{{ 'HEAD_OFFICE' | translate }}</span> 
                  </div>
                </div>
                <p>
                  60 Paya Lebar Road #11-47, Paya Lebar Square, Singapore,
                  409051
                </p>
              </li>
              <li class="mb-3">
                <div class="location-item">
                  <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M9.03 1.99535e-05C11.29 0.01002 13.45 0.91002 15.03 2.49002C16.62 4.08002 17.51 6.23002 17.5 8.46002V8.51002C17.44 11.54 15.74 14.33 13.62 16.51C12.42 17.74 11.09 18.83 9.64 19.75C9.25 20.08 8.68 20.08 8.29 19.75C6.14 18.35 4.24 16.59 2.7 14.54C1.35 12.76 0.58 10.62 0.5 8.39002C0.52 3.74002 4.34 -0.00998005 9.03 1.99535e-05ZM9.03 11.26C9.74 11.26 10.42 10.99 10.92 10.5C11.44 9.99002 11.731 9.31102 11.731 8.60002C11.731 7.12002 10.52 5.93002 9.03 5.93002C7.54 5.93002 6.34 7.12002 6.34 8.60002C6.34 10.061 7.52 11.24 9 11.26H9.03Z"
                      fill="#F3F4F8" />
                  </svg>

                  <span>{{ 'INDONESIA' | translate }}</span>
                </div>
                <p>
                  Ciputra International Office Tower, 17th Floor Suite 01, Outer Ring Road No. 101, West Jakarta,
                  Indonesia, 11740
                </p>
              </li>
              <li class="mb-3">
                <div class="location-item">
                  <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M9.03 1.99535e-05C11.29 0.01002 13.45 0.91002 15.03 2.49002C16.62 4.08002 17.51 6.23002 17.5 8.46002V8.51002C17.44 11.54 15.74 14.33 13.62 16.51C12.42 17.74 11.09 18.83 9.64 19.75C9.25 20.08 8.68 20.08 8.29 19.75C6.14 18.35 4.24 16.59 2.7 14.54C1.35 12.76 0.58 10.62 0.5 8.39002C0.52 3.74002 4.34 -0.00998005 9.03 1.99535e-05ZM9.03 11.26C9.74 11.26 10.42 10.99 10.92 10.5C11.44 9.99002 11.731 9.31102 11.731 8.60002C11.731 7.12002 10.52 5.93002 9.03 5.93002C7.54 5.93002 6.34 7.12002 6.34 8.60002C6.34 10.061 7.52 11.24 9 11.26H9.03Z"
                      fill="#F3F4F8" />
                  </svg>

                  <span>{{ 'TAIWAN' | translate }}</span>
                </div>
                <p>
                  10F 6, No. 250, Section 4, Zhongxiao E Rd, Da’an District, Taipei City 106, Taiwan
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="row copyright">
      <div class="col-lg-6 d-flex justify-content-md-start align-items-center justify-content-sm-center">
        <span>Ideku Technology Solution Pte Ltd &copy; 2022 - {{ year }}</span>
        <span>&copy; 2022 - {{ year }} &nbsp; Ideku</span>
      </div>

      <div
        class="col-lg-6 d-flex justify-content-md-end align-items-center justify-content-sm-center"
      >
        <a href="/{{currentLanguage}}/terms-and-conditions" class="text-white">{{
          "FOOTER_NAV.TNC" | translate
        }}</a>
        &nbsp; | &nbsp;<a
          href="/{{currentLanguage}}/privacy-policy"
          class="text-white"
          >{{ "FOOTER_NAV.PRIVACY_POLICY" | translate }}</a
        >
      </div>
    </div>
  </div>

  <img src="assets/images/png/idepos-wings.png" alt="idepos-wings" class="logo idepos-wings" fetchpriority="low"
    loading="lazy" />
</footer>

<!-- <div class="floating-contact" (click)="openContact()">
  <img src="assets/images/svg/whatsapp.svg" alt="whatsapp" />

  <p *ngIf="langCode === 'id'">
    <ngx-typed-js
      [strings]="['Mau Kami Bantu? </br> Hubungi Tim Ideku']"
      contentType="html"
      [loop]="true"
      [showCursor]="false"
      [backDelay]="10000"
    >
      <p class="typing"></p>
    </ngx-typed-js>
  </p>

  <p *ngIf="langCode === 'en'">
    <ngx-typed-js
      [strings]="['Any Enquiries and Need Help? </br> Contact Ideku Team']"
      contentType="html"
      [loop]="true"
      [showCursor]="false"
      [backDelay]="10000"
    >
      <p class="typing"></p>
    </ngx-typed-js>
  </p>
</div> -->