import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GtmService {
  private isGtmLoaded = false;

  constructor(
    @Inject(DOCUMENT) private document: Document
  ) {}

  initializeGtm(gtmId: string) {
    // Check if exists tag, if not exist, should adding the new tag
    if (!this.isGtmLoaded && !this.document.querySelector(`script[src*="googletagmanager.com/gtm.js?id=${gtmId}"]`)) {
      // add script GTM
      const script = this.document.createElement('script');
      script.innerHTML = `
        (function(w,d,s,l,i){
          w[l]=w[l]||[];
          w[l].push({'gtm.start': new Date().getTime(), event:'gtm.js'});
          var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${gtmId}');
      `;
      this.document.head.appendChild(script);

      // add noscript GTM
      const noscript = this.document.createElement('noscript');
      noscript.innerHTML = `
        <iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>
      `;
      this.document.body.appendChild(noscript);

      this.isGtmLoaded = true;
    }
  }
}
