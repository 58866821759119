import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  LIST_FEATURES,
  LIST_SERVICES,
  NavbarMenuModel,
} from '@layouts/ideku-page.model';

@Component({
  selector: 'app-header-privacy-agreement',
  templateUrl: './header-privacy-agreement.component.html',
  styleUrls: ['./header-privacy-agreement.component.scss'],
})
export class HeaderPrivacyAgreementComponent implements OnInit {
  isBrowser?: boolean;
  isServer?: boolean;

  listFeatures: NavbarMenuModel[] = [];
  listServices: NavbarMenuModel[] = [];

  imgIdeku!: string;
  imgIdePos!: string;
  isKarir:boolean = false;
  width:number = 0;

  searchEl:any = null;

  constructor(
    @Inject(PLATFORM_ID) private platform: Object,
    @Inject(PLATFORM_ID) private document: Document,
    private router: Router,
  ) {
    this.isBrowser = isPlatformBrowser(platform);
  }
  ngOnInit(): void {
    this.width = $(this.document).width() ?? 0;
    if(this.isBrowser){
      if(this.router.url.includes('career')){
        if(this.width > 992) this.isKarir = true;
      }else{
        this.isKarir = false;
      }
    }
    this.listFeatures = LIST_FEATURES;
    this.listServices = LIST_SERVICES;
  }
  highlight(elem:any, keywords:any) {
    let flags = 'gi';
    let cls =  'highlight';
    Array.from(elem.childNodes).forEach((child:any) => {
      let keywordRegex = RegExp(keywords, flags);
      if (child.nodeType !== 3) { // not a text node
        this.highlight(child, keywords);
      } else if (keywordRegex.test(child.textContent)) {
        let frag = this.document.createDocumentFragment();
        let lastIdx = 0;
        child.textContent.replace(keywordRegex, (match:any, idx:any) => {
          let part = this.document.createTextNode(child.textContent.slice(lastIdx, idx));
          let highlighted = this.document.createElement('span');
          highlighted.textContent = match;
          highlighted.classList.add(cls);
          frag.appendChild(part);
          frag.appendChild(highlighted);
          lastIdx = idx + match.length;
        });
        let end = this.document.createTextNode(child.textContent.slice(lastIdx));
        frag.appendChild(end);
        child.parentNode.replaceChild(frag, child);
      }
    });
  }
  search(text:string){
    let searchText: any = this.document.getElementById("searchText");
    if(!this.searchEl){
      this.searchEl = searchText.innerHTML;
    }
    let innerHTML = this.searchEl;
    searchText.innerHTML = innerHTML;
    this.highlight(searchText,text);
    const bodyWidth = $('body').width() ?? 0
    let gapTop = bodyWidth > 992 ? 100 : 200;
    setTimeout(()=>{
      $(window).scrollTop($('.highlight:first').position().top - gapTop);
    },400)
  }

  @HostListener('window:scroll', ['$event'])
  initializationStickyNavbarCategory() {
    $( ()=> {
      $(window).on('scroll',  ()=> {
        if (($(window).scrollTop() ?? 0) > 0) {
          $('.navbar-menu').addClass('bt-ideku');
          $('.navbar-menu').addClass('bg-white');
          if(this.router.url.includes('career')){
            this.isKarir = false;
          }
        }
        if($(window).scrollTop() == 0){
          $('.navbar-menu').removeClass('bt-ideku');
          $('.navbar-menu').removeClass('bg-white');
          if(this.router.url.includes('career')){
            if(this.width > 992) this.isKarir = true;
          }

        }
      });
    });
  }

  changeLogoMenuService(menuName: string, sourceImage: string): void {
    if (menuName === 'ideku') this.imgIdeku = sourceImage;
    if (menuName === 'idepos') this.imgIdePos = sourceImage;
  }

  getImage(menuName: string) {
    if (menuName == 'ideku')
      return this.imgIdeku ? this.imgIdeku : 'logo-ideku-beta';
    return this.imgIdePos ? this.imgIdePos : 'logo-idepos-beta';
  }
}
