import { Component } from '@angular/core';

@Component({
  selector: 'app-ideshop-page',
  templateUrl: './ideshop-page.component.html',
  styleUrls: ['./ideshop-page.component.scss']
})
export class IdeshopPageComponent {

}
