import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-ideku-page',
  templateUrl: './ideku-page.component.html',
  styleUrls: ['./ideku-page.component.scss'],
})
export class IdekuPageComponent implements OnInit {
  showPrivacy: boolean = false;
  @Input() header: any;
  @Input() showHeader: boolean = true;
  @Input() isIdepage: boolean = false;

  constructor(private _activatedRoute: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    this._activatedRoute.queryParams.subscribe((res) => {
      if (
        this.router.url.includes('terms-and-conditions') ||
        this.router.url.includes('privacy-policy')
      ) {
        this.showPrivacy = true;
      } else {
        this.showPrivacy = false;
      }
    });
  }
}
