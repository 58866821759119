<nav
  class="site-header-privacy-agreement sticky-top navbar navbar-expand-lg navbar-light fixed-top py-3 navbar-menu"
>
  <div class="container">
    <a class="navbar-brand" href="/">
      <img
        src="assets/images/png/logo-ideku-black.png"
        style="margin-top: -14px"
        width="100"
        alt="ideku"
        fetchpriority="low"
        loading="lazy"
      />
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mb-2 mx-auto ml-0 mb-lg-0">
        <li class="nav-item page">
          <div class="position-relative search-box">
            <input
              type="text"
              name=""
              class="search"
              #inp
              (keyup)="search(inp.value)"
              [placeholder]="'WHAT_CAN_I_HELP_YOU' | translate"
              id=""
            />
            <img
              (click)="search(inp.value)"
              class="logo-search"
              src="assets/images/png/search.png"
              alt="search icon"
              fetchpriority="low"
              loading="lazy"
            />
          </div>
        </li>
      </ul>
      <a class="tim-support" href="/contact-us">{{
        "NAV_MENU.CONTACT_SUPPORT" | translate
      }}</a>
    </div>
  </div>
</nav>
