import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-idepage',
  templateUrl: './header-idepage.component.html',
  styleUrls: ['./header-idepage.component.scss']
})
export class HeaderIdepageComponent implements OnInit {

  constructor(
    @Inject(DOCUMENT) private document: Document,
  ) { }

  ngOnInit(): void {
  }

  @HostListener('window:scroll', ['$event'])
  initializationStickyNavbarCategory() {
    $( ()=> {
      $(window).on('scroll',  ()=> {
        if ($(window).scrollTop() ?? 0 > 0) {
          $('.navbar-menu').addClass('bt-ideku');
          $('.navbar-menu').addClass('bg-white');
        }
        if($(window).scrollTop() == 0){
          $('.navbar-menu').removeClass('bt-ideku');
          $('.navbar-menu').removeClass('bg-white');
        }
      });
    });
  }


}
