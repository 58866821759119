import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID
} from '@angular/core';
import { Router } from '@angular/router';
import {
  LIST_FEATURES,
  LIST_SERVICES,
  NavbarMenuModel,
} from '@layouts/ideku-page.model';
import { TranslateService } from '@ngx-translate/core';
import { ViewService } from '../../data-access/view/view.service';
import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() hideMenu: boolean = false;
  @Input() isIdepage: boolean = false;
  @Input() scrollToID: string = '';
  isBrowser?: boolean;
  isServer?: boolean;
  listFeatures: NavbarMenuModel[] = [];
  listServices: NavbarMenuModel[] = [];

  imgIdeku!: string;
  imgIdePos!: string;
  isCareer: boolean = false;
  isHome: boolean = false;
  width: number = 0;
  langCode: string = 'en';
  currentLanguage: string = 'en';

  languages = [
    {
      image: 'assets/images/logo/sg-logo.png',
      name: 'SG (EN)',
      value: 'en',
      order: 1,
    },
    {
      image: 'assets/images/logo/tw-logo.svg',
      name: '繁體中文',
      value: 'zh-TW',
      order: 2,
    },
    {
      image: 'assets/images/logo/cn-logo.svg',
      name: '简体中文',
      value: 'zh-CN',
      order: 3,
    },
    {
      image: 'assets/images/logo/id-logo.png',
      name: 'ID',
      value: 'id',
      order: 4,
    },
  ];

  @Input() header: any;

  showProductDropdown: boolean = false;
  showLanguageDropdown: boolean = false;

  country: string = ''
  isCollapsed: boolean = true
  showIntegrationDropdown: boolean = false

  menuIntegrationItems = [
    {
      label: "NAV_MENU.DELIVERY",
      link: '#',
      showSubmenu: false,
      submenu: [
        { label: 'GrabFood', link: `/integration/delivery/grabfood` },
      ]
    },
    {
      label: 'NAV_MENU.PAYMENT',
      link: '#',
      showSubmenu: false,
      submenu: [
        { label: 'Stripe', link: `/integration/payment/stripe` },
      ]
    }
  ];


  constructor(
    private translate: TranslateService,
    @Inject(PLATFORM_ID) private platform: Object,
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private _viewService: ViewService,
    private elementRef: ElementRef,
    private configService: ConfigService
  ) {
    if (isPlatformBrowser(this.platform)) {
      this.currentLanguage = this.configService.getLanguage();
      this.initializeIntegrationItems()
      this.country = this._viewService.getLocalStorage('countryCode') ?? ''
    }
  }

  ngOnInit(): void {
    if (this.isBrowser) {
      this.width = $(this.document).width() ?? 0;
      if (this.router.url.includes('career')) {
        if (this.width > 992) this.isCareer = true;
      } else {
        this.isCareer = false;
      }
      if (this.router.url == '/') {
        this.isHome = true;
      } else {
        this.isHome = false;
      }
    }
    this.listFeatures = LIST_FEATURES;
    this.listServices = LIST_SERVICES;
  }

  initializeIntegrationItems() {
    this.menuIntegrationItems.map((item) => { 
      item.submenu.map((subItem) => {
        subItem.link = `/${this.currentLanguage}${subItem.link}`
      })
    })
  }


  @HostListener('window:scroll', ['$event'])
  initializationStickyNavbarCategory() {
    $(() => {
      $(window).on('scroll', () => {
        if ($(window).scrollTop() ?? 0 > 0) {
          $('.navbar-menu').addClass('bt-ideku');
          $('.navbar-menu').addClass('bg-white');
          if (this.router.url.includes('career')) {
            this.isCareer = false;
          }
        }
        if ($(window).scrollTop() == 0) {
          $('.navbar-menu').removeClass('bt-ideku');
          $('.navbar-menu').removeClass('bg-white');
          if (this.router.url.includes('career')) {
            if (this.width > 992) this.isCareer = true;
          }
        }
      });
    });
  }

  changeLogoMenuService(menuName: string, sourceImage: string): void {
    if (menuName === 'ideku') this.imgIdeku = sourceImage;
    if (menuName === 'idepos') this.imgIdePos = sourceImage;
  }

  getImage(menuName: string) {
    if (menuName == 'ideku')
      return this.imgIdeku ? this.imgIdeku : 'logo-ideku-beta';
    return this.imgIdePos ? this.imgIdePos : 'logo-idepos-beta';
  }

  setLanguage(language: string, isReloading: boolean) {
    this.configService.setLanguage(language);
    this.currentLanguage = language;
    this.translate.use(this.currentLanguage);

    let index = 1;
    for (let lang of this.languages) {
      if (lang.value === language) {
        lang.order = index;
      } else {
        lang.order += index;
      }
    }
  }

  sortBy(prop: string) {
    return this.languages.sort((a: any, b: any) =>
      a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1
    );
  }

  dropdownClick(name: string) {
  this.showProductDropdown = name === 'product' ? !this.showProductDropdown : false;
  this.showLanguageDropdown = name === 'language' ? !this.showLanguageDropdown : false;
  this.showIntegrationDropdown = name === 'integration' ? !this.showIntegrationDropdown : false;
}

  // Outside click listener to close dropdowns when clicked elsewhere
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const targetElement = event.target as HTMLElement;

    // Check if clicked element is not within any dropdown
    const clickedInsideProductDropdown = this.elementRef.nativeElement.querySelector('.product-dropdown')?.contains(targetElement);
    const clickedInsideLanguageDropdown = this.elementRef.nativeElement.querySelector('.language-dropdown')?.contains(targetElement);
    const clickedInsideIntegrationDropdown = this.elementRef.nativeElement.querySelector('.integration-dropdown')?.contains(targetElement);

    if (!clickedInsideProductDropdown && !clickedInsideLanguageDropdown && !clickedInsideIntegrationDropdown) {
      this.showProductDropdown = false;
      this.showLanguageDropdown = false;
      this.showIntegrationDropdown = false;
    }
  }

  scrollToElement() {
    let element = document.getElementById(this.scrollToID);
    element?.scrollIntoView({behavior:'smooth'});
  }
}
