export class NavbarMenuModel {
    url!: string;
    menuName!: string;
    isActive?: boolean;
    imgBefore?: string;
    imgAfter?: string;
}


export const LIST_FEATURES: NavbarMenuModel[] = [
    {
        url: 'management-product',
        menuName: 'Manajemen Produk',
        isActive: true,
    },
    {
        url: '#',
        menuName: 'Manajemen Pesanan',
        isActive: false,
    },
    {
        url: '#',
        menuName: 'Manajemen Persediaan',
        isActive: false,
    },
    {
        url: '#',
        menuName: 'Manajemen Point Of Sale',
        isActive: false,
    },
    {
        url: '#',
        menuName: 'Manajemen Pengiriman',
        isActive: false,
    },
    {
        url: '#',
        menuName: 'Manajemen Sosial Media',
        isActive: false,
    },
    {
        url: '#',
        menuName: 'Manajemen Chat',
        isActive: false,
    }
];

export const LIST_SERVICES: NavbarMenuModel[] = [
    {
        url: '#',
        menuName: 'ideku',
        imgBefore: 'logo-ideku-beta',
        imgAfter: 'logo-ideku-beta-white',
        isActive: true
    },
    {
        url: '#',
        menuName: 'idepos',
        imgBefore: 'logo-idepos-beta',
        imgAfter: 'logo-idepos-beta-white',
        isActive: false
    },
];