<nav
  class="site-header navbar navbar-expand-lg navbar-light fixed-top py-3 navbar-menu"
>
  <div class="container">
    <a class="navbar-brand" href="/">
      <img
        src="assets/images/png/logo-ideshop-black.png"
        width="100"
        alt="ideku"
        fetchpriority="low"
        loading="lazy"
      />
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mb-2 ms-auto ml-0 mb-lg-0">
        <li class="nav-item nav-btn">
          <a class="nav-link" href="https://portal.ideku.io/login">
            <button class="outlined-button">
              {{ "NAV_MENU.LOGIN_BUTTON" | translate }}
            </button>
          </a>
        </li>
        <li class="nav-item nav-btn">
          <a class="nav-link" href="https://portal.ideku.io/register">
            <button class="filled-button">
              {{ "NAV_MENU.TRY_BUTTON" | translate }}
            </button>
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>
