<div class="container my-5">
  <div class="row">
    <div class="col-lg-6">
      <img src="assets/images/png/thinking-face-cuate.png" alt="thingking face cuate">
    </div>
    <div class="col-lg-6">
      <p>Kindly Wait !</p>
      <h3>We’ll Be<br>Right Back</h3>
      <a href="/" class="btn-ideku mt-4">
        Back to Home
      </a>
    </div>
  </div>
</div>
