import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgxTypedJsModule } from 'ngx-typed-js';
import { FooterComponent } from './footer/footer.component';
import { HeaderIdepageComponent } from './header-idepage/header-idepage.component';
import { HeaderIdeshopComponent } from './header-ideshop/header-ideshop.component';
import { HeaderPrivacyAgreementComponent } from './header-privacy-agreement/header-privacy-agreement.component';
import { HeaderComponent } from './header/header.component';
import { IdekuPageComponent } from './ideku-page/ideku-page.component';
import { IdepagePageComponent } from './idepage-page/idepage-page.component';
import { IdeshopPageComponent } from './ideshop-page/ideshop-page.component';
import { DropdownComponent } from '../ui/dropdown/dropdown.component';

@NgModule({
  declarations: [
    HeaderComponent,
    HeaderPrivacyAgreementComponent,
    FooterComponent,
    IdekuPageComponent,
    IdepagePageComponent,
    HeaderIdepageComponent,
    HeaderIdeshopComponent,
    IdeshopPageComponent,
  ],
  imports: [CommonModule, RouterModule, TranslateModule, NgxTypedJsModule, DropdownComponent],
  providers: [],
  exports: [
    HeaderComponent,
    HeaderPrivacyAgreementComponent,
    FooterComponent,
    IdekuPageComponent,
    IdepagePageComponent,
    HeaderIdepageComponent,
    HeaderIdeshopComponent,
    IdeshopPageComponent,
  ],
})
export class IdekuPageModule {}
